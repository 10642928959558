import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import '../../styles/common.css';
import Select from '@paljs/ui/Select';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  authCheck,
  dateFormat,
  dateGreaterOrEqual,
  buildQuery,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import { CSVLink, CSVDownload } from 'react-csv';
import ReactPaginate from 'react-paginate';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  padding: 4px;
  margin-bottom: 10px;
`;

const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const SpecGenReport = () => {
  const [customerOptions, setCustomerOptions] = useState([]);
  const [sortOptions, setSortOptions] = useState([]);
  const [pocOptions, setPocOptions] = useState([]);
  const [labels, setLabels] = useState({
    customer: '',
    sort: [],
    poc: [],
    shadeString: '',
    blend: {
      blend1: {
        name: '',
        percent: '',
      },
      blend2: {
        name: '',
        percent: '',
      },
      blend3: {
        name: '',
        percent: '',
      },
    },
    finishRequired: [],
    sortCode: '',
    finishedWidth: '',
    type: '',
    processRequired: [],
  });
  const [apiResponse, setApiResponse] = useState([]);
  const [currSpecHeadData, setCurrSpecHeadData] = useState({
    customer: { name: '', id: '' },
    poc: { id: '' },
    sort: { sortName: '', id: '' },
  });
  const [itemData, setItemData] = useState();
  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState([]);
  const [showDownload, setShowDownload] = useState(false);
  const optionsSetter = (response: any, customer = true, sort = true, jobCard = true, poc = true) => {
    const currJobCardOptions: any = [];
    const currCustomerOptions: any = [];
    const currCustSortOptions: any = [];
    const currPocOptions: any = [];
    response.forEach((t: any) => {
      t = t?.attributes?.jobCard.data;
      let temCustOpt = {
        label: `${t?.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.user?.data?.attributes?.name}`,
        value: `${t?.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.user?.data?.id}`,
      };
      let temSortOpt = {
        label: `${t?.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.sortName}`,
        value: `${t?.attributes?.processOrder?.data?.attributes?.customersSort?.data?.id}`,
      };
      let temPocOptions = {
        label: `${t?.attributes?.processOrder?.data?.id}`,
        value: `${t?.attributes?.processOrder?.data?.id}`,
      };
      if (temSortOpt.label !== 'undefined') {
        currCustSortOptions.push(temSortOpt);
      }
      if (temCustOpt.label !== 'undefined') {
        currCustomerOptions.push(temCustOpt);
      }
      if (temPocOptions.label !== 'undefined') {
        currPocOptions.push(temPocOptions);
      }
    });
    if (customer) {
      setCustomerOptions(arrUnique(currCustomerOptions));
    }
    if (poc) {
      setPocOptions(arrUnique(currPocOptions));
    }
    if (sort) {
      setSortOptions(arrUnique(currCustSortOptions));
    }
  };
  const getApi = () => {
    axios
      .get(
        `${baseUri()}packing-slips?sort[0]=jobCard.id:desc&fields[0]=dispatch&fields[1]=cotton&fields[2]=mmf&populate[jobCard][fields][0]=idLotNum&populate[jobCard][populate][processOrder][fields][0]=id&populate[jobCard][populate][processOrder][fields][1]=shade&populate[jobCard][populate][processOrder][fields][3]=finishWidth&populate[jobCard][populate][processOrder][fields][4]=finishRequired&populate[jobCard][populate][processOrder][fields][5]=pocFinish&populate[jobCard][populate][processOrder][fields][2]=pocType&populate[jobCard][populate][processOrder][fields][4]=processRequired&populate[jobCard][populate][processOrder][populate][customersSort][populate][user][fields][0]=name&populate[jobCard][populate][processOrder][populate][customersSort][fields][0]=sortName&populate[jobCard][populate][processOrder][populate][customersSort][populate][sort]=*`,
      )
      .then((response) => {
        response = response.data.data;
        setApiResponse(response);
        optionsSetter(response);
        hideLoader();
      });
  };
  const arrUnique = (arr) => {
    const map = new Map();
    arr.forEach((itm: any) => {
      map.set(JSON.stringify(itm), itm);
    });
    return [...map.values()];
  };
  const customerFilterOptions = (labelValue = labels.customer.label) => {
    let filteredApiResponse = apiResponse.filter(
      (t: any) =>
        t.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.user
          ?.data?.attributes?.name === labelValue,
    );
    return filteredApiResponse;
  };
  const sortFilterOptions = (currLabel = labels.sort) => {
    let filteredApiResponse = apiResponse.filter(
      (t: any) =>
        t.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes
          ?.sortName === currLabel.label,
    );
    return filteredApiResponse;
  };
  const pocFilterOptions = (currLabel = labels.poc) => {
    let filteredApiResponse = apiResponse.filter((t: any) => {
      let isFiltered = false;
      currLabel.forEach((label) => {
        if (+label.label === +t?.attributes?.jobCard?.data?.attributes?.processOrder?.data?.id) {
          isFiltered = true;
        }
      });
      return isFiltered;
    });
    return filteredApiResponse;
  };

  const localeNumStr = (num) => {
    let temp: any = +num;
    temp = temp.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return temp;
  };
  const specGenReportGetter = async () => {
    let res = await axios.get(
      `${baseUri()}reports/poc-status?poc=${labels.poc.map((t) => +t.label).join(',')}&customerId=${
        labels.customer.value
      }&sortId=${labels.sort.value}`,
    );
    res = await res.data.data;
    console.log(res);
    setTableData(res);
    setItemData(res);
  };
  const columns = [
    {
      name: 'POC Num',
      selector: (row) => `${row.POC_Num}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'POC Date',
      selector: (row) => `${row.POC_Date}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'POC Status',
      selector: (row) => `${row.POC_Status}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Customer Name',
      selector: (row) => `${row.Customer_Name}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Customer Sort',
      selector: (row) => `${row.Customer_Sort}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'POC Qty',
      selector: (row) => `${row.POC_Qty}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Process Type',
      selector: (row) => `${row.Process_Type}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Colour',
      selector: (row) => `${row.Colour}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Receipt No',
      selector: (row) => `${row.Receipt_Number}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Receipt Date',
      selector: (row) => `${row.GRA_Receipt_Date}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Recipt Totl Mts',
      selector: (row) => `${row.Receipt_Totl_Mts}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Recipt Qty',
      selector: (row) => `${row.POC_GRA_Receipt_qty}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Lot Num',
      selector: (row) => `${row.LOT_Num}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Job Crd No',
      selector: (row) => `${row.JOB_Card_Num}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Job Crd Date',
      selector: (row) => `${row.JOB_Card_Date}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Job Crd Mts',
      selector: (row) => `${row.JOB_Card_Mts}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Job Crd Sts',
      selector: (row) => `${row.JOB_Card_Status}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Job Crd Sts Date',
      selector: (row) => `${row.JOB_Card_Status_Date}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Bale No',
      selector: (row) => `${row.bale_no}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'PS Date',
      selector: (row) => `${row.ps_date}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Bale Total',
      selector: (row) => `${row.bale_total}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Spec Gen',
      selector: (row) => `${row['Spec Gen']}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Spec Gen Date',
      selector: (row) => `${row.SPEC_GEN_Date}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Dispatched',
      selector: (row) => `${row.Dispatched}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Dispatch Date',
      selector: (row) => `${row.Dispatch_Date}`,
      sortable: true,
      maxWidth: '45%',
    },
  ];
  const filterColumns = [
    'POC_Num',
    'POC_Date',
    'POC_Status',
    'Customer_Name',
    'Cusomter_Sort',
    'POC_Qty',
    'Process_Type',
    'Colour',
    'Receipt_Number',
    'GRA_Receipt_Date',
    'Receipt_Totl_Mts',
    'POC_GRA_Receipt_qty',
    'LOT_Num',
    'JOB_Card_Num',
    'JOB_Card_Date',
    'JOB_Card_Mts',
    'JOB_Card_Status',
    'JOB_Card_Status_Date',
    'bale_no',
    'ps_date',
    'bale_total',
    'Spec Gen',
    'SPEC_GEN_Date',
    'Dispatched',
    'Dispatch_Date',
  ];
  const filterChange = (searchInput: any, newData = false) => {
    setTableFilter(searchInput);
    let available: any = [];
    if (newData) {
      available = newData;
    } else {
      available = itemData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns, true);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };
  const resetForm = () => {
    setLabels({
      customer: '',
      poc: '',
      sort: '',
    });
    setTimeout(() => {
      optionsSetter(apiResponse);
    }, 500);
  };

  useEffect(() => {
    if (tableData.length) {
      setShowDownload(true);
    } else {
      setShowDownload(false);
    }
  }, [tableData]);
  useEffect(() => {
    showLoader();
    getApi();
  }, []);
  return (
    <>
      <SEO title="Spec Gen / Dispatch" />
      <Col breakPoint={{ xs: 12, md: 12 }}>
        <Card>
          <header> Spec Gen Report</header>
          <CardBody className="cardbody">
            <Row>
              <Col breakPoint={{ xs: 12, md: 4 }}>
                <label>Customer</label>
                <SelectField
                  fullWidth
                  options={customerOptions}
                  id="customer"
                  name="customer"
                  placeholder="Select Customer"
                  value={labels.customer}
                  onChange={(e) => {
                    setLabels({
                      ...labels,
                      poc: [],
                      sort: '',
                      customer: {
                        label: e.label,
                        value: e.value,
                      },
                      shadeString: '',
                      blend: {
                        blend1: {
                          name: '',
                          percent: '',
                        },
                        blend2: {
                          name: '',
                          percent: '',
                        },
                        blend3: {
                          name: '',
                          percent: '',
                        },
                      },
                      finishRequired: [],
                      sortCode: '',
                      finishedWidth: '',
                      type: '',
                      processRequired: [],
                    });
                    optionsSetter(customerFilterOptions(e.label), false);
                  }}
                  className="pull-down multi-select"
                />
              </Col>
              <Col breakPoint={{ xs: 12, md: 4 }}>
                <label>Customer Sort</label>
                <SelectField
                  fullWidth
                  options={sortOptions}
                  id="customerSort"
                  name="customerSort"
                  placeholder="Select Customer Sort"
                  value={labels.sort}
                  onChange={(e) => {
                    const currFilteredSortOptions = sortFilterOptions(e);
                    setLabels({
                      ...labels,
                      sort: e,
                      customer: {
                        label:
                          currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data
                            ?.attributes?.customersSort?.data?.attributes?.user?.data?.attributes?.name,
                        value:
                          currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data
                            ?.attributes?.customersSort?.data?.attributes?.user?.data?.id,
                      },
                      poc: [
                        {
                          label:
                            currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data?.id,
                          value:
                            currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data?.id,
                        },
                      ],
                      shadeString: `${currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.pocType}-${currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.shade}`,
                      blend: {
                        blend1: {
                          name: currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data
                            ?.attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blend1,
                          percent:
                            currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data
                              ?.attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blendPercent1,
                        },
                        blend2: {
                          name: currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data
                            ?.attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blend2,
                          percent:
                            currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data
                              ?.attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blendPercent2,
                        },
                        blend3: {
                          name: currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data
                            ?.attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blend3,
                          percent:
                            currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data
                              ?.attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blendPercent3,
                        },
                      },
                      finishRequired: [
                        ...[
                          ...currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.finishRequired.filter(
                            (t) => t.checked && t.name !== 'Others',
                          ),
                        ].map((t) => t.name),
                        currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes
                          ?.pocFinish,
                      ],
                      sortCode:
                        currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes
                          ?.customersSort?.data?.attributes?.sort?.data?.attributes?.sortCode,
                      finishedWidth:
                        currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes
                          ?.finishWidth,
                      type: currFilteredSortOptions[0].attributes.cotton === true ? 'cotton' : 'mmf',
                      processRequired: [
                        ...currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.processRequired.filter(
                          (t) => t.checked,
                        ),
                      ].map((t) => t.name),
                    });
                    optionsSetter(currFilteredSortOptions, false, false);
                  }}
                  className="pull-down multi-select"
                />
              </Col>
              <Col breakPoint={{ xs: 12, md: 4 }}>
                <label>POC</label>
                <SelectField
                  fullWidth
                  isMulti
                  options={pocOptions}
                  onChange={(e) => {
                    const currFilteredPOCOptions = pocFilterOptions(e);
                    console.log(currFilteredPOCOptions);
                    if (currFilteredPOCOptions.length > 0) {
                      setLabels({
                        ...labels,
                        poc: e,
                        sort: {
                          label:
                            currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data
                              ?.attributes?.customersSort?.data?.attributes?.sortName,
                          value:
                            currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data
                              ?.attributes?.customersSort?.data?.id,
                        },
                        customer: {
                          label:
                            currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data
                              ?.attributes?.customersSort?.data?.attributes?.user?.data?.attributes?.name,
                          value:
                            currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data
                              ?.attributes?.customersSort?.data?.attributes?.user?.data?.id,
                        },
                        shadeString: `${currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.pocType}-${currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.shade}`,
                        blend: {
                          blend1: {
                            name: currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data
                              ?.attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blend1,
                            percent:
                              currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data
                                ?.attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blendPercent1,
                          },
                          blend2: {
                            name: currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data
                              ?.attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blend2,
                            percent:
                              currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data
                                ?.attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blendPercent2,
                          },
                          blend3: {
                            name: currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data
                              ?.attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blend3,
                            percent:
                              currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data
                                ?.attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blendPercent3,
                          },
                        },
                        finishRequired: [
                          ...[
                            ...currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.finishRequired.filter(
                              (t) => t.checked && t.name !== 'Others',
                            ),
                          ].map((t) => t.name),
                          currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data
                            ?.attributes?.pocFinish,
                        ],
                        sortCode:
                          currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data
                            ?.attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.sortCode,
                        finishedWidth:
                          currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data
                            ?.attributes?.finishWidth,
                        type: currFilteredPOCOptions[0]?.attributes.cotton === true ? 'cotton' : 'mmf',
                        processRequired: [
                          ...currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.processRequired.filter(
                            (a) => a.checked,
                          ),
                        ].map((a) => a.name),
                      });
                      optionsSetter(currFilteredPOCOptions, false, true, true, false);
                    } else {
                      resetForm();
                    }
                  }}
                  id="customerSort"
                  name="customerSort"
                  placeholder="Select POC"
                  value={labels.poc}
                  className="pull-down multi-select"
                />
              </Col>
              {/* <Col
                breakPoint={{ xs: 12, md: 4 }}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {labels.shadeString}
                </span>
              </Col> */}
              <Col
                breakPoint={{ xs: 12, md: 4 }}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Button
                  status="Success"
                  type="button"
                  className="btnrad"
                  disabled={labels.poc.length === 0}
                  halfWidth
                  onClick={() => {
                    specGenReportGetter();
                    // getPackingSlips();
                  }}
                >
                  Get Report
                </Button>
                &nbsp;&nbsp;
                <Button
                  type="button"
                  className="btnrad"
                  shape=""
                  halfWidth
                  onClick={() => {
                    setLabels({
                      customer: '',
                      poc: '',
                      sort: '',
                    });
                    setTimeout(() => {
                      optionsSetter(apiResponse);
                    }, 500);
                  }}
                >
                  Reset
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <header>Spec Gen Report</header>
          <CardBody>
            {showDownload && (
                
              <p>
                <CSVLink data={tableData}>Download Excel</CSVLink>
              </p>
            )}
            <Input fullWidth size="Small">
              <input
                type="text"
                onChange={(e) => filterChange(e.target.value)}
                placeholder="Filter"
                name="tableFilter"
                value={tableFilter}
              />
            </Input>
            <DataTable
              columns={columns}
              data={tableData}
              fixedHeader
              allowOverflow={false}
              overflowY={false}
              overflowX={false}
              wrap={false}
              highlightOnHover={true}
              pagination={true}
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            />
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default SpecGenReport;
